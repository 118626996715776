import {
  SAVE_STORE_INFO,
  SAVE_ADDRESS_INFO,
  SAVE_ADDRESS,
  SAVE_POSTAL_CODE,
  SAVE_BUSINESS_HOURS,
  CLEAR_STORE,
} from "../actions/actionTypes";

const initialState = {
  postalCodeText: "",
  addressText: "",
  store: null,
  address: null,
  businessHours: [],
};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_ADDRESS: {
      const data = action.payload;
      return {
        ...state,
        addressText: data,
      };
    }
    case SAVE_POSTAL_CODE: {
      const data = action.payload;
      return {
        ...state,
        postalCodeText: data,
      };
    }
    case SAVE_STORE_INFO: {
      const data = action.payload;
      return {
        ...state,
        store: data,
      };
    }
    case SAVE_ADDRESS_INFO: {
      const data = action.payload;
      return {
        ...state,
        address: data,
      };
    }
    case SAVE_BUSINESS_HOURS: {
      const data = action.payload;
      return {
        ...state,
        businessHours: data,
      };
    }
    case CLEAR_STORE: {
      return {
        ...state,
        store: null,
      };
    }
    default: {
      return state;
    }
  }
}
