import { all } from "redux-saga/effects";
import orderSaga from "./order.saga";
import menuSaga from "./menu.saga";
import storeSaga from "./store.saga";
import paymentSage from "./payment.saga";
import customerSaga from "./customer.saga";

export default function createRootSaga() {
  return function* rootSaga() {
    yield all([
      orderSaga(),
      menuSaga(),
      storeSaga(),
      paymentSage(),
      customerSaga(),
    ]);
  };
}
