import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Image from "next/image";
import React from "react";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: ${({ height }) => (height ? height : "200px")};
`;

const Load = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loading = (props) => {
  const { className, type, size, height } = props;
  const color = "grey.500";
  return type === "spin" ? (
    <CircularProgress className={className} size={size} sx={{ color }} />
  ) : (
    <Backdrop
      sx={{
        color,
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
      invisible={true}
      open={true}
    >
      {/*<CircularProgress className={className} size={size} color="inherit" />*/}
      <Image src="icons/loader-bike.gif" width="30px" height="30px"></Image>
    </Backdrop>
  );
};

Loading.defaultProps = {
  type: "frame",
  size: 30,
};

export default Loading;
