import dayjs from "dayjs";

import { common } from "../../constants";

export const now = () => {
  return dayjs.tz().format('YYYY-MM-DD HH:mm:ss');
};

export const getBusinessHours = (orderType = 1, businessHours = []) => {
  return businessHours.map((item, i) => {
    let today = false;
    let firstTimeSlot = null;
    if (i == 0) {
      today = true;
      firstTimeSlot = {
        time: "ASAP",
        time_label: "asap",
      };
    }

    const date = {
      date: item.date,
      day: item.day,
      today,
      date_label: dayjs(item.date).format("MM-DD"),
      waiting_time: item.waiting_time,
      start_at: item.start_at,
      end_at: item.end_at,
    };

    let timeSlots = [];
    if (orderType == common.ORDER_TYPE.DELIVERY) {
      timeSlots = _getDeliveryTimeSlots(item);
    } else {
      timeSlots = _getTakeawayTimeSlots(item);
    }
    if (firstTimeSlot) {
      timeSlots = [firstTimeSlot, ...timeSlots];
    }
    return { ...date, time_slots: timeSlots };
  });
};

function _getDeliveryTimeSlots(slotInfo) {
  const stack = _createTimeStack(slotInfo);
  const times = [];
  for (let i in stack) {
    i = parseInt(i);
    if (i % 2 == 0) continue;

    const prev = stack[i - 1];
    const current = stack[i];
    const next = stack[i + 1];

    const dayjsPrev = dayjs(prev);
    const dayjsCurrent = dayjs(current);
    times.push({
      date_time: dayjsPrev.format("YYYY-MM-DD HH:mm:ss"),
      time: dayjsPrev.format("HH:mm:ss"),
      time_label: `${dayjsPrev.format("HH:mm")} - ${dayjsCurrent.format(
        "HH:mm",
      )}`,
    });
    if (!next) return times;
    const dayjsNext = dayjs(next);
    times.push({
      date_time: dayjsCurrent.format("YYYY-MM-DD HH:mm:ss"),
      time: dayjsCurrent.format("HH:mm:ss"),
      time_label: `${dayjsCurrent.format("HH:mm")} - ${dayjsNext.format(
        "HH:mm",
      )}`,
    });
  }
  return times;
}

function _getTakeawayTimeSlots(slotInfo) {
  const stack = _createTimeStack(slotInfo);
  return stack.map((item) => {
    const time = dayjs(item);
    return {
      date_time: time.format("YYYY-MM-DD HH:mm:ss"),
      time: time.format("HH:mm:ss"),
      time_label: time.format("HH:mm"),
    };
  });
}

function _createTimeStack(slotInfo) {
  const { date, duration, end_at, start_at, waiting_time } = slotInfo;
  const startTimestamp = dayjs(start_at).valueOf();
  const endTimestamp = dayjs(end_at).valueOf();
  const waitingTimeMillisecond = waiting_time * 60 * 1000
  const durationMillisecond = duration * 60 * 1000

  let i = startTimestamp + waitingTimeMillisecond + durationMillisecond;
  const stack = [];
  while (i <= endTimestamp) {
    const time = dayjs(i);
    const h = time.format('HH');
    const m = time.format('mm');
    stack.push(`${date} ${h}:${m}:00`);
    i += durationMillisecond;
  }
  return stack;
}

export const filterTimeSlot = (timeSlot = [], waitingTime = 0)=> {
  const timeNow = dayjs.tz().add(waitingTime + 15, 'm').format('YYYY-MM-DD HH:mm:ss');
  return timeSlot.filter(item => {
    return item.time == 'ASAP' || item.date_time > timeNow;
  });
}

export const filterDateSlot = (businessHours = [])=> {
  const today = businessHours.find(item => item.today);
  if(!today) return businessHours;
  const timeSlotToday = filterTimeSlot(today.time_slots, today.waiting_time);
  if(timeSlotToday.length == 1) {
    return businessHours.filter(item => !item.today);
  }
  return businessHours;
}

export const generateCardMonth = () => {
  return Array(12).fill().map((_, i) => ('0' + (i + 1)).slice(-2));
}

export const generateCardYear = () => {
  const currentYear = dayjs().year();
  const result = [currentYear + ""];
  for(let i = 1; i <= 20; i++) {
    result.push((currentYear + i) + "");
  }
  return result;
}
