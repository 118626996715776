import {
  LOAD_MENU,
  SAVE_PRODUCTS,
  SAVE_CURRENT_MENU,
  GET_CHILD_PRODUCTS,
  GET_TOPPINGS,
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const actionLoadMenu = () => ({
  type: LOAD_MENU,
});

export const actionSaveProducts = (payload) => ({
  type: SAVE_PRODUCTS,
  payload,
});

export const actionSaveCurrentMenu = (payload) => ({
  type: SAVE_CURRENT_MENU,
  payload,
});

export const actionPromiseGetChildProducts =
  createPromiseAction(GET_CHILD_PRODUCTS);

export const actionPromiseGetToppings = createPromiseAction(GET_TOPPINGS);
