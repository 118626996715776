import { toast } from "react-toastify";
import { actionShowAppDialogNotify, actionShowAppLoading } from "redux/actions/app";
import store from "../redux/store";
import _ from "lodash";
import dayjs from "dayjs";
import { now } from "./Datetime";

export const showAppError = (message, autoTrans = true) => {
  const transMsg = toast.t && autoTrans ? toast.t(message) : message;
  toast.error(transMsg, { toastId: message });
};

export const showAppSuccess = (message) => {
  const transMsg = toast.t ? toast.t(message) : message;
  toast.success(transMsg, { toastId: message });
};

export const showAppLoading = (isLoading = true) => {
  store.dispatch(actionShowAppLoading(isLoading));
};

export const showAppDialogNotify = (open, message = "") => {
  store.dispatch(actionShowAppDialogNotify({ open, message }));
};

export const getCurrentLocation = async (defaultIfErr = true, showErrMsg = true) => {
  const TIMEOUT_DURATION = 10000;
  const DEFAULT = {
    // Tokyo
    lat: 35.679739,
    lon: 139.766571,
  };
  let timeout = null;
  return new Promise((resolve) => {
    const successHandler = (position) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      const { latitude, longitude } = position.coords;
      resolve({ latitude, longitude });
    };

    const errorHandler = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (showErrMsg) {
        showAppError("Cannot get your location!");
      }

      if (defaultIfErr) {
        return resolve({ latitude: DEFAULT.lat, longitude: DEFAULT.lon });
      }
      return resolve(null);
    };

    navigator.geolocation.getCurrentPosition(
      (position) => successHandler(position),
      (err) => errorHandler(),
      { timeout: TIMEOUT_DURATION },
    );
    timeout = setTimeout(() => errorHandler(), TIMEOUT_DURATION);
  });
};

export const getSizeAndCrustMasterFromOption = (options = []) => {
  const sizeKeyByUUID = {};
  const crustKeyByUUID = {};
  options.forEach((item) => {
    sizeKeyByUUID[item.option_group_uuid] = _.pick(item, [
      "option_group_uuid",
      "option_group_code",
      "option_group_name",
      "option_group_sequence",
    ]);
    crustKeyByUUID[item.option_uuid] = _.pick(item, ["option_uuid", "option_code", "option_name", "option_sequence"]);
  });
  return {
    size: _.orderBy(_.values(sizeKeyByUUID), "option_group_sequence"),
    crust: _.orderBy(_.values(crustKeyByUUID), "option_sequence"),
  };
};

export const getSizeFromOptions = (options = []) => {
  const sizeKeyByUUID = _.keyBy(options, "option_group_uuid");
  return _.orderBy(_.values(sizeKeyByUUID), "option_group_sequence");
};

export const fakeForm = (action, method, data = {}) => {
  const form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", action);

  for (const field in data) {
    const value = data[field];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        for (const key in item) {
          const value = item[key];
          createFakeFormInput(form, key, value);
        }
      });
      continue;
    }
    createFakeFormInput(form, field, value);
  }
  document.body.appendChild(form);
  return form;
};

function createFakeFormInput(form, field, value) {
  const input = document.createElement("input");
  input.setAttribute("type", "hidden");
  input.setAttribute("name", field);
  input.setAttribute("value", value);

  form.appendChild(input);
}

export const filterByAvailableTime = (data, { date, time }) => {
  const orderTime = time == "ASAP" ? dayjs(now()).valueOf() : dayjs(`${date} ${time}`).valueOf();
  return data.filter(({ start_time, end_time }) => {
    if (!start_time || !end_time) return false;
    const startTime = dayjs(start_time).valueOf();
    const endTime = dayjs(end_time).valueOf();
    return startTime <= orderTime && orderTime <= endTime;
  });
};

export const sortBySequence = (data = []) => {
  return _.orderBy(data, "sequence");
};

export const filterByOutOfStock = (data) => {
  return data.reduce((result, product) => {
    if (!product.is_out_of_stock) {
      product.options = product.options.filter((opt) => !opt.is_out_of_stock);
      result.push(product);
    }
    return result;
  }, []);
};

export const getError = (error) => {
  if (error.response) {
    if (Array.isArray(error.response.data)) {
      const msgList = error.response.data.map((err) => err.message);
      return [...new Set(msgList)].join("<br/>");
    }
    return error.response.data.message;
  }
  try {
    const json = JSON.parse(error.message);
    return json.message;
  } catch (err) {
    return error.message;
  }
};
