// import { useEffect } from 'react';
import Head from "next/head";
import dynamic from "next/dynamic";
import "styles/globals.css";

import { Provider } from "react-redux";
import store, { persistor } from "redux/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import { PersistGate } from "redux-persist/integration/react";
import i18n from "i18n";

import Loading from "components/Utils/Loading";

import { defaultTitle } from "constants";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "grey" },
          style: {
            textTransform: "none",
            padding: "10px 16px",
            background: "#f2f4f7",
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: "none",
          lineHeight: 2,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#70A401",
    },
  },
});

const GTMId = process.env.NEXT_PUBLIC_GTM_ID;
const Layout = dynamic(() => import("layout"), { loading: () => <Loading /> });
const MyApp = ({ Component, pageProps }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Head>
            <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_SERVER_API} />
            <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_STATIC} />
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
            />
            <meta name="description" content="Description" />
            <meta name="keywords" content="Keywords" />
            <title>{defaultTitle}</title>
            {/* Google Tag Manager */}
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTMId}');`,
              }}
            />
            {/* End Google Tag Manager */}
          </Head>
          <ThemeProvider theme={theme}>
            <Layout>
              {/* Google Tag Manager (noscript) */}
              <noscript>
                <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${GTMId}`}
                  height="0"
                  width="0"
                  style="display:none; visibility:hidden"
                ></iframe>
              </noscript>
              {/* End Google Tag Manager (noscript) */}
              <Component {...pageProps} />
            </Layout>
            {process.env.NEXT_PUBLIC_ENV !== "production" ? (
              <div id="mobile-debug" style={{ wordWrap: "break-word" }} />
            ) : (
              ""
            )}
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  );
};

export default MyApp;
