import storeReducer from './storeReducer';
import orderReducer from './orderReducer';
import menuReducer from './menuReducer';
import appReducer from './appReducer';
import customerReducer from './customerReducer';
import paymentReducer from './paymentReducer';

export default {
    appReducer,
    storeReducer,
    orderReducer,
    menuReducer,
    customerReducer,
    paymentReducer,
}
