import { menuAPI } from "../../constants";
import { fetchData } from "../fetch";

export const getCategories = () => {
  const url = `${menuAPI}/get/categories`;
  return fetchData({
    method: "get",
    url,
  });
};

export const getSizeAndCrustMaster = () => {
  const url = `${menuAPI}/get/size-crust`;
  return fetchData({
    method: "get",
    url,
  });
};

export const getProducts = (storeUUID, orderType, productUUID = "") => {
  // If productUUID => Get Product from Combo or H&H
  let url = `${menuAPI}/get/products/${storeUUID}/line/${orderType}`;
  if (productUUID) {
    url += `/${productUUID}`;
  }
  // const url = `${menuAPI}/get/products/72d7f675-b35a-4413-8156-75c58b451926/line/1`;
  return fetchData({
    method: "get",
    url,
  });
};

export const getHalfAndHalfs = (storeUUID, orderType) => {
  let url = `${menuAPI}/get/hnh/${storeUUID}/line/${orderType}`;
  return fetchData({
    method: "get",
    url,
  });
};
