import { call, all, put, select, takeEvery } from "redux-saga/effects";

import {
  CLICK_SAVE_STORE_INFO,
  SAVE_STORE_INFO,
  SAVE_ORDER_SCHEDULE,
  SAVE_BUSINESS_HOURS,
} from "../actions/actionTypes";

import {filterDateSlot, getBusinessHours} from "utils/Datetime";
import dayjs from "dayjs";

function* saveStoreInfo({ payload }) {
  if(!payload) return yield put({ type: SAVE_STORE_INFO, payload: null });

  const orderType = yield select(({ orderReducer }) => orderReducer.orderType);
  const orderSchedule = yield select(
    ({ orderReducer }) => orderReducer.orderSchedule,
  );

  const businessHours = getBusinessHours(orderType, payload.business_hours);

  const [dateSlotDefault] = filterDateSlot(businessHours);

  const now = dayjs.tz().valueOf();
  payload.opened =
    now > dayjs.tz(dateSlotDefault.start_at).valueOf() &&
    now < dayjs.tz(dateSlotDefault.end_at).valueOf();
  payload.business_hour = dateSlotDefault;

  let timeSlotDefault = dateSlotDefault.time_slots[0] || {};
  if (!payload.opened) {
    timeSlotDefault = dateSlotDefault.time_slots[1] || {};
  }

  yield all([
    put({
      type: SAVE_BUSINESS_HOURS,
      payload: businessHours,
    }),
    put({
      type: SAVE_ORDER_SCHEDULE,
      payload: { ...orderSchedule, ...dateSlotDefault, ...timeSlotDefault },
    }),
    put({
      type: SAVE_STORE_INFO,
      payload,
    }),
  ]);
}

export default function* storeSaga() {
  yield takeEvery(CLICK_SAVE_STORE_INFO, saveStoreInfo);
}
