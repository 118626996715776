import { common } from "constants";

export const gtmLoadMenu = () => {
  dataLayer.push({
    event: "productView",
    ecommerce: {
      currencyCode: "JPY",
      impressions: [
        {
          name: "all product load",
          id: "1",
          price: 1,
          brand: "lma",
          category: "lma",
          variant: "",
          list: "main-list",
          position: 0,
        },
      ],
    },
  });
};

export const gtmAddToCart = (product = {}) => {
  dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "JPY",
      add: {
        products: [
          {
            name: product.product_name || "",
            id: product.product_uuid || "",
            price: product.price || 0,
            category: getCategoryName(product.category_type),
            variant: "",
            quantity: 1,
          },
        ],
      },
    },
  });
};

export const gtmClickCheckout = (products = []) => {
  const productData = products.map(product => {
    const productInfo = product.type == "product" ? product.product[0] || {} : product.parent;
    return {
      name: productInfo.name || '',
      id: productInfo.uuid || '',
      price: productInfo.price || 0,
      brand: "",
      category: getCategoryName(productInfo.category.type),
      variant: "",
      quantity: productInfo.quantity || 0,
    }
  });
  dataLayer.push({
    event: "checkout",
    ecommerce: {
      currencyCode: "JPY",
      checkout: {
        products: productData,
      },
    },
  });
};

export const gtmPurchaseSuccessful = (order = {}) => {
  const { uuid, store_uuid, total, order_detail } = order;

  const products = order_detail.map(row => {
    return {
      // List productField
      name: row.name, // Name or ID is required.
      id: row.uuid,
      price: row.price,
      brand: "",
      category: getCategoryName(row.category_type),
      variant: "",
      quantity: row.quantity,
      coupon: "", // Optional fields may be omitted or set to empty string.
    }
  });
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: uuid, // Order uuid
          affiliation: store_uuid, // Store uuid
          revenue: total, // Total transaction value
          currency: "jpy",
        },
        products
      },
    },
  });
};

function getCategoryName(categoryType) {
  switch (categoryType) {
    case common.CATEGORY_TYPE.NORMAL:
      return "Normal";
    case common.CATEGORY_TYPE.PIZZA:
      return "Pizza";
    case common.CATEGORY_TYPE.COMBO:
      return "Combo";
    case common.CATEGORY_TYPE.H_AND_H:
      return "Half & Half";
    default:
      return "";
  }
}
