import { menuAPI } from "../../constants";
import { fetchData } from "../fetch";

export const getToppings = (
  storeUUID,
  orderType,
  productUUID,
  optionGroupUUID,
) => {
  // const url = `${menuAPI}/get/toppings/72d7f675-b35a-4413-8156-75c58b451926/line/1/02dff6c7-1b08-4739-a91e-46064bd6583a/194ac804-f2c5-457d-be89-cc540d4b045d`;
  const url = `${menuAPI}/get/toppings/${storeUUID}/line/${orderType}/${productUUID}/${optionGroupUUID}`;
  return fetchData({
    method: "get",
    url,
  });
};
