import { PAYMENT_SAVE_METHOD } from '../actions/actionTypes';

const initialState = {
    isMethodLoaded: false,
    paymentMethod: [],
};

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_SAVE_METHOD: {
            return {
                ...state,
                paymentMethod: action.payload,
                isMethodLoaded: true,
            };
        }
        default: {
            return state;
        }
    }
};

export default paymentReducer;
