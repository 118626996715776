export const getCache = (key) => {
    if (typeof window !== 'undefined') {
        const value = window.localStorage.getItem(key);
        return value ? value : '';
    }
    return '';
};

export const setCache = (key, value) => {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, value);
    }
};

export const clearCache = (key) => {
    if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key);
    }
};