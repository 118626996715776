import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  GET_CART,
  CLICK_ADD_TO_CART,
  SAVE_CART_INFO,
  CLICK_SAVE_ORDER_SCHEDULE,
  SAVE_ORDER_SCHEDULE,
  CLEAR_CART,
  CLICK_DELETE_CART,
  UPDATE_CHECKOUT_INFO,
  SAVE_CHECKOUT_INFO,
  UPDATE_ORDER_SCHEDULE,
} from "../actions/actionTypes";

import { getToppings } from "services/menu/topping";
import { updateCollectionTime } from "services/order/cart";
import {
  addToCart as addToCartService,
  getCart as getCartService,
  deleteCart as deleteCartService,
} from "services/order/cart";
import { common } from "constants";
import { showAppLoading, showAppSuccess } from "utils";
import { gtmAddToCart } from "utils/Google/gtmEvent";

function* addToCart({ payload }) {
  showAppLoading();
  const t = payload.t;
  t && delete payload.t;

  const orderSchedule = yield select(({ orderReducer }) => orderReducer.orderSchedule);
  const store = yield select(({ storeReducer }) => storeReducer.store);
  const orderType = yield select(({ orderReducer }) => orderReducer.orderType);
  const cartInfo = yield select(({ orderReducer }) => orderReducer.cart);
  const token = yield select(({ customerReducer }) => customerReducer.token);
  const prevOrderAddrSelected = yield select(({ customerReducer }) => customerReducer.prevOrderAddrSelected);

  let cartUUID = '';
  let customerAddress = '';
  if (cartInfo) {
    cartUUID = cartInfo.information.cart_uuid || "";
    customerAddress = cartInfo.information.full_address_customer || "";
  }

  const {
    date,
    day,
    date_label,
    time,
    time_label,
    today,
    waiting_time,
    start_at,
    end_at,
  } = orderSchedule;

  const {
    full_address = '',
    lat = 0,
    long = 0,
    postal_code = '',
    address_level_0 = '',
    address_level_1 = '',
    address_level_2 = '',
    address_level_3 = '',
    address_level_4 = '',
    address_level_5 = '',
    address_level_6 = '',
    address_level_7 = '',
    address_level_8 = '',
    address_level_9 = '',
  } = prevOrderAddrSelected || {};

  const nowOrder = orderSchedule.time == "ASAP" ? 1 : 0;
  const collectionTime = nowOrder
    ? null
    : dayjs
        .tz(`${orderSchedule.date} ${orderSchedule.time}`, store.timezone)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

  let cartItemType = "product";
  if (payload.category_type == common.CATEGORY_TYPE.COMBO) {
    cartItemType = "combo";
  } else if (payload.category_type == common.CATEGORY_TYPE.H_AND_H) {
    cartItemType = "half_half";
  }

  const isPizza = payload.category_type == common.CATEGORY_TYPE.PIZZA;
  const isNormal = payload.category_type == common.CATEGORY_TYPE.NORMAL;

  let products = [];
  if (isPizza || isNormal) {
    const { default: defaultTopping, additional, removal } = payload.toppings || { default: [], additional: [], removal: [] };
    const toppingAdded =
      defaultTopping.length || additional.length || removal.length;

    if (!toppingAdded) {
      const sizeUUID = isNormal ? common.NORMAL_SIZE : payload.option_group_uuid;
      const topping = yield call(
        getToppings,
        store.uuid,
        orderType,
        payload.product_uuid,
        sizeUUID,
      );
      if (!topping) return showAppLoading(false);
      const { default_toppings } = topping;
      payload = {
        ...payload,
        toppings: {
          removal: [],
          additional: [],
          default: default_toppings.map((item) => ({
            uuid: item.uuid,
            quantity: item.quantity,
          })),
        },
      };
    }
    products = [
      {
        quantity: 1,
        ...payload,
      },
    ];
  } else {
    for (const product of payload.products) {
      const isPizza = product.category_type == common.CATEGORY_TYPE.PIZZA;
      if (!isPizza && !isNormal) continue;

      const { default: defaultTopping, additional, removal } = product.toppings || { default: [], additional: [], removal: [] };
      const toppingAdded =
        defaultTopping.length || additional.length || removal.length;
      if (toppingAdded) continue;

      const sizeUUID = isNormal ? common.NORMAL_SIZE : product.option_group_uuid;
      const topping = yield call(
        getToppings,
        store.uuid,
        orderType,
        product.product_uuid,
        sizeUUID,
      );
      if (!topping) return showAppLoading(false);
      const { default_toppings } = topping;
      product.toppings = {
        removal: [],
        additional: [],
        default: default_toppings.map((item) => ({
          uuid: item.uuid,
          quantity: item.quantity,
        })),
      };
    }
    products = payload.products;
  }

  const data = {
    cart_uuid: cartUUID,
    disposition: orderType,
    items: [
      {
        combo_uuid: payload.combo_uuid || "",
        promotion_code: "",
        products,
        quantity: 1,
        type: cartItemType,
      },
    ],
    now_order: nowOrder,
    collection_time: collectionTime,
    store_uuid: store.uuid,
    address_mapping_uuid: store.address_mapping_uuid,
    full_address_customer: customerAddress || (prevOrderAddrSelected || {}).full_address_customer || '',
    delivery: {
      full_address,
      lat,
      long,
      postal_code,
      address_level_0,
      address_level_1,
      address_level_2,
      address_level_3,
      address_level_4,
      address_level_5,
      address_level_6,
      address_level_7,
      address_level_8,
      address_level_9,
    },
    collection_info: {
      date,
      day,
      date_label,
      time,
      time_label,
      today,
      waiting_time,
      start_at,
      end_at,
    },
  };

  const newCartInfo = yield call(addToCartService, data, token);
  if (!newCartInfo) return showAppLoading(false);
  yield put({ type: SAVE_CART_INFO, payload: newCartInfo });
  showAppLoading(false);
  showAppSuccess(t ? t("added_to_basket") : "Added to basket!");
  gtmAddToCart(payload);
}

function* getCart() {
  const orderType = yield select(({ orderReducer }) => orderReducer.orderType);
  const store = yield select(({ storeReducer }) => storeReducer.store);
  const token = yield select(({ customerReducer }) => customerReducer.token);

  const cartInfo = yield call(getCartService, store.uuid, orderType, token);
  if (!cartInfo) return;
  yield put({ type: SAVE_CART_INFO, payload: cartInfo });
}

function* deleteCart() {
  const cart = yield select(({ orderReducer }) => orderReducer.cart);
  const token = yield select(({ customerReducer }) => customerReducer.token);
  const cartUUID = cart ? cart.information.cart_uuid : "";
  yield put({ type: CLEAR_CART });
  if (!cartUUID) return;
  yield call(deleteCartService, cartUUID, token);
}

function* saveOrderSchedule({ payload }) {
  yield put({ type: SAVE_ORDER_SCHEDULE, payload });

  const store = yield select(({ storeReducer }) => storeReducer.store);
  const cart = yield select(({ orderReducer }) => orderReducer.cart);
  const token = yield select(({ customerReducer }) => customerReducer.token);

  const collectionTime =
    payload.time == "ASAP"
      ? null
      : dayjs
          .tz(`${payload.date} ${payload.time}`, store.timezone)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
  const cartUUID = cart ? cart.information.cart_uuid : "";
  if (!cartUUID) return;

  const {
    date,
    day,
    date_label,
    time,
    time_label,
    today,
    waiting_time,
    start_at,
    end_at,
  } = payload;
  
  const collectionInfo = {
    date,
    day,
    date_label,
    time,
    time_label,
    today,
    waiting_time,
    start_at,
    end_at,
  };

  const newCartInfo = yield call(
    updateCollectionTime,
    cartUUID,
    collectionTime,
    collectionInfo,
    token,
  );

  if (!newCartInfo) return;
  yield put({ type: SAVE_CART_INFO, payload: newCartInfo });
}

function* updateCheckoutInfo({ payload }) {
  const { field, value } = payload;
  const checkoutInfo = yield select(
    ({ orderReducer }) => orderReducer.checkoutInfo,
  );
  const data = { ...checkoutInfo, [field]: value };
  yield put({ type: SAVE_CHECKOUT_INFO, payload: data });
}

function* updateOrderSchedule({ payload }) {
  const orderSchedule = yield select(({ orderReducer }) => orderReducer.orderSchedule);

  const data = {};
  for(const i in orderSchedule) {
    if (!payload[i]) {
      data[i] = orderSchedule[i];
      continue;
    };
    data[i] = payload[i];
  }

  yield put({ type: SAVE_ORDER_SCHEDULE, payload: data });
}

export default function* orderSaga() {
  yield takeEvery(GET_CART, getCart);
  yield takeEvery(CLICK_ADD_TO_CART, addToCart);
  yield takeEvery(CLICK_SAVE_ORDER_SCHEDULE, saveOrderSchedule);
  yield takeEvery(CLICK_DELETE_CART, deleteCart);
  yield takeEvery(UPDATE_CHECKOUT_INFO, updateCheckoutInfo);
  yield takeEvery(UPDATE_ORDER_SCHEDULE, updateOrderSchedule);
}
