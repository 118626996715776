import axios from "axios";
import { headers as defaultHeaders } from "constants";
import { showAppError } from "../../utils";

const successCode = 200;
export const fetchData = async (
  {
    method,
    url,
    headers = {},
    originalData = false,
    showErrMsg = true,
  },
  data,
) => {
  try {
    const config = { method, url };
    if (method === "get" || method === "GET") {
      config.params = data;
    } else {
      config.data = data;
    }
    config.headers = {
      ...defaultHeaders,
      ...headers,
    };
    const res = await axios(config);
    const result = res.data;
    if (result.code === successCode) {
      if (originalData) return result || true;
      return result.data || true;
    }
    if (showErrMsg) {
      showAppError(result.message);
    }
    return false;
  } catch (error) {
    const response = error.response || {};
    const errorInfo = response.data || {};
    const myMess = errorInfo.message || error.message;
    console.log(url + " ERROR:", myMess);
    if (showErrMsg) {
      showAppError(myMess);
    }
    return false;
  }
};

// For Google, AWS... api
export const fetchThirdParty = async (
  { method, url, headers = {}, originalData = false },
  data,
) => {
  try {
    const config = { method, url };
    if (method === "get" || method === "GET") {
      config.params = data;
    } else {
      config.data = data;
    }
    config.headers = headers;
    const result = await axios(config);
    if (result.status === successCode) {
      if (originalData) return result || true;
      return result.data || true;
    }
    return false;
  } catch (error) {
    const response = error.response || {};
    const errorInfo = response.data || {};
    const myMess = errorInfo.message || error.message;
    console.log(url + " ERROR:", myMess);
    return false;
  }
};
