import {
  SHOW_APP_DIALOG_NOTIFY,
  SHOW_APP_LOADING,
  SHOW_APP_MESSAGE,
} from "../actions/actionTypes";

const initialState = {
  appLoading: false,
  appMessage: {
    open: false,
    message: '',
    type: 'info'
  },
  appDialogNotify: {
    open: false,
    message: '',
  },
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_APP_LOADING: {
      const data = action.payload;
      return {
        ...state,
        appLoading: data,
      };
    }
    case SHOW_APP_MESSAGE: {
      const data = action.payload;
      return {
        ...state,
        appMessage: data,
      };
    }
    case SHOW_APP_DIALOG_NOTIFY: {
      const data = action.payload;
      return {
        ...state,
        appDialogNotify: data,
      };
    }
    default: {
      return state;
    }
  }
}
