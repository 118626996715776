import {
  CUSTOMER_SAVE_USER,
  SAVE_PREV_ORDER_INFO,
  SAVE_PREV_ADDR_SELECTED,
} from "../actions/actionTypes";

const initialState = {
  uuid: "",
  name: "",
  email: "",
  token: "",
  prevOrderInfo: {
    delivery_addresses: [],
    collection_stores: [],
  },
  prevOrderAddrSelected: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_SAVE_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SAVE_PREV_ORDER_INFO: {
      return {
        ...state,
        prevOrderInfo: action.payload,
      };
    }
    case SAVE_PREV_ADDR_SELECTED: {
      return {
        ...state,
        prevOrderAddrSelected: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default customerReducer;
