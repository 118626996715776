import { paymentAPI } from "constants";
import { fetchData } from "../fetch";

export const serviceGetPaymentMethod = () => {
  const url = `${paymentAPI}/online/methods`;

  return fetchData({
    method: "get",
    url,
  });
};

export const serviceSBPGPayment = (authToken, token, tokenKey, orderUUID) => {
  const url = `${paymentAPI}/online/sb-credit`;
  const data = {
    token,
    token_key: tokenKey,
    order_uuid: orderUUID,
  };
  return fetchData(
    {
      method: "post",
      url,
      headers: {
        "x-token": authToken,
      },
    },
    data,
  );
};
