import { customerAPI } from "constants";
import { fetchData } from "../fetch";

export const serviceGetLineCustomerById = (token, accessToken, isLine) => {
  const url = `${customerAPI}/line/profile${!isLine ? `?lineID=${token}` : ""}`;
  const headers = {};
  if (isLine) {
    headers["x-token"] = token;
    headers["x-access-token"] = accessToken;
  }
  return fetchData({
    method: "get",
    url,
    headers,
  });
};

export const serviceGetSavedInformation = (accessToken) => {
  const url = `${customerAPI}/line/saved-information`;
  const headers = {
    "x-token": accessToken,
  };
  return fetchData({
    method: "get",
    url,
    headers,
  });
};
