import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Tokyo");

import {
  SAVE_ORDER_TYPE,
  SAVE_CART_INFO,
  SAVE_ORDER_SCHEDULE,
  CLEAR_CART,
  SAVE_CHECKOUT_INFO,
} from "../actions/actionTypes";

const now = dayjs.tz();

const initialState = {
  orderType: 1,
  orderSchedule: {
    date: now.format("YYYY-MM-DD"),
    day: now.format("ddd"),
    date_label: now.format("MM-DD"),
    time: "ASAP",
    time_label: "ASAP",
    today: true,
  },
  cart: null,
  checkoutInfo: {},
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_ORDER_TYPE: {
      const data = action.payload;
      return {
        ...state,
        orderType: data,
      };
    }
    case SAVE_CART_INFO: {
      const data = action.payload;
      return {
        ...state,
        cart: data,
      };
    }
    case SAVE_ORDER_SCHEDULE: {
      const data = action.payload;
      return {
        ...state,
        orderSchedule: data,
      };
    }
    case CLEAR_CART: {
      return {
        ...state,
        cart: null,
      };
    }
    case SAVE_CHECKOUT_INFO: {
      const data = action.payload;
      return {
        ...state,
        checkoutInfo: data,
      };
    }
    default: {
      return state;
    }
  }
}
