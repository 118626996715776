import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { promiseMiddleware } from "@adobe/redux-saga-promise";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";
import createRootSaga from "../sagas";
import { persistStore, persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
}

const sagaMiddleware = createSagaMiddleware();
const createReducer = (asyncReducers = {}) => {
  return combineReducers({
    ...rootReducer,
    ...asyncReducers,
  });
};

const persistedReducer = persistReducer(persistConfig, createReducer())

const composeSetup =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
// Configure the store
const configureStore = (initialState) => {
  const store = createStore(persistedReducer, initialState);

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  store.runSaga = sagaMiddleware.run;
  // Return the modified store
  return store;
};

// const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
const store = configureStore(
  composeSetup(applyMiddleware(promiseMiddleware, sagaMiddleware)),
);
const persistor = persistStore(store)
export { store as default, persistor };

const rootSaga = createRootSaga();
sagaMiddleware.run(rootSaga);
