export const SHOW_APP_MESSAGE = "SHOW_APP_MESSAGE";
export const SHOW_APP_LOADING = "SHOW_APP_LOADING";
export const SHOW_APP_DIALOG_NOTIFY = "SHOW_APP_DIALOG_NOTIFY";

export const SAVE_POSTAL_CODE = "SAVE_POSTAL_CODE";
export const SAVE_ADDRESS = "SAVE_ADDRESS";
export const CLICK_SAVE_STORE_INFO = "CLICK_SAVE_STORE_INFO";
export const SAVE_STORE_INFO = "SAVE_STORE_INFO";
export const SAVE_ADDRESS_INFO = "SAVE_ADDRESS_INFO";
export const SAVE_BUSINESS_HOURS = "SAVE_BUSINESS_HOURS";
export const CLEAR_STORE = "CLEAR_STORE";

export const SAVE_ORDER_TYPE = "SAVE_ORDER_TYPE";
export const GET_CART = "GET_CART";
export const CLEAR_CART = "CLEAR_CART";
export const CLICK_DELETE_CART = "CLICK_DELETE_CART";
export const CLICK_ADD_TO_CART = "CLICK_ADD_TO_CART";
export const SAVE_CART_INFO = "SAVE_CART_INFO";
export const SAVE_ORDER_SCHEDULE = "SAVE_ORDER_SCHEDULE";
export const UPDATE_ORDER_SCHEDULE = "UPDATE_ORDER_SCHEDULE";
export const CLICK_SAVE_ORDER_SCHEDULE = "CLICK_SAVE_ORDER_SCHEDULE";
export const UPDATE_CHECKOUT_INFO = "UPDATE_CHECKOUT_INFO";
export const SAVE_CHECKOUT_INFO = "SAVE_CHECKOUT_INFO";

export const LOAD_MENU = "LOAD_MENU";
export const SAVE_CATEGORIES = "SAVE_CATEGORIES";
export const SAVE_PRODUCTS = "SAVE_PRODUCTS";
export const SAVE_CURRENT_MENU = "SAVE_CURRENT_MENU";
export const GET_CHILD_PRODUCTS = "GET_CHILD_PRODUCTS";
export const GET_TOPPINGS = "GET_TOPPINGS";

export const CUSTOMER_SAVE_USER = "CUSTOMER_SAVE_USER";
export const SAVE_PREV_ADDR_SELECTED = "SAVE_PREV_ADDR_SELECTED";
export const SAVE_PREV_ORDER_INFO = "SAVE_PREV_ORDER_INFO";
export const GET_PREV_ORDER_INFO = "GET_PREV_ORDER_INFO";

export const PAYMENT_GET_METHOD = "PAYMENT_GET_METHOD";
export const PAYMENT_SAVE_METHOD = "PAYMENT_SAVE_METHOD";
