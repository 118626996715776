import { call, all, put, select, takeEvery } from "redux-saga/effects";

import {
  GET_PREV_ORDER_INFO,
  SAVE_PREV_ORDER_INFO,
} from "../actions/actionTypes";

import { serviceGetSavedInformation } from "../../services/customer";

function* getPrevOrderInfo() {
  const token = yield select(({ customerReducer }) => customerReducer.token);
  const data = yield call(serviceGetSavedInformation, token);

  if (data) {
    yield put({
      type: SAVE_PREV_ORDER_INFO,
      payload: data,
    });
  }
}

export default function* customerSaga() {
  yield takeEvery(GET_PREV_ORDER_INFO, getPrevOrderInfo);
}
