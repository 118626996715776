import { call, put, select, takeEvery } from 'redux-saga/effects';
import { PAYMENT_GET_METHOD, PAYMENT_SAVE_METHOD } from 'redux/actions/actionTypes';
import { serviceGetPaymentMethod } from 'services/payment';

function* initGetPaymentMethod(action) {
    const isMethodLoaded = yield select(({ paymentReducer }) => paymentReducer.isMethodLoaded);
    if (!isMethodLoaded) {
        const data = yield call(serviceGetPaymentMethod);
        if (data) {
            yield put({ type: PAYMENT_SAVE_METHOD, payload: data });
        }
    }
}

export default function* paymentLoad() {
    yield takeEvery(PAYMENT_GET_METHOD, initGetPaymentMethod);
}
