const defaultTitle = "ピザハット";
const googleAPIKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
const generatePaymentTokenLib = process.env.NEXT_PUBLIC_SBPS_SYSTEM_TOKEN_LIB;
const host = process.env.NEXT_PUBLIC_SERVER_API;
const version = "v1";

const storeAPI = host + "/store";
const systemAPI = host + "/system/" + version;
const menuAPI = host + "/menu";
const cartAPI = host + "/cart";
const orderAPI = host + "/order";
const customerAPI = host + "/customer";
const paymentAPI = host + "/payment/" + version;
const contentAPI = host + "/content/" + version;

const clientID = "3b8a7c10-b1b1-11e9-8e0f-4389b2fc3a77";
const deviceID = "620005a5-1305-4668-9fb2-3ba250a57ab9";
const deviceType = "phone";
const lang = "en";
const channel = 2;

const headers = {
  "X-CLIENT-ID": clientID,
  "X-DEVICE-ID": deviceID,
  "X-DEVICE-TYPE": deviceType,
  "X-LANG": lang,
  "X-CHANNEL": channel,
  "X-PLATFORM": "LINE",
};

const common = {
  ORDER_TYPE: {
    DELIVERY: 1,
    TAKEAWAY: 2,
  },
  ORDER_TYPE_CODE: {
    DELIVERY: "DELIVERY",
    TAKEAWAY: "TAKEAWAY",
  },
  CATEGORY_TYPE: {
    NORMAL: 1,
    PIZZA: 2,
    COMBO: 3,
    H_AND_H: 4,
  },
  MAX_OF_COLLECTION_DAY: 45,
  DEFAULT_IMG: 'https://i.imgur.com/gzihice.jpg',
  MAX_TOPPING_QTY: 3,
  MAX_MENU_QTY: 99,
  NORMAL_SIZE: '2864958f-ff67-4415-af31-6cc7efe59186',
};

export {
  googleAPIKey,
  defaultTitle,
  headers,
  host,
  storeAPI,
  systemAPI,
  menuAPI,
  common,
  customerAPI,
  cartAPI,
  paymentAPI,
  orderAPI,
  contentAPI,
  generatePaymentTokenLib,
};
