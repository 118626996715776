import { cartAPI } from "../../constants";
import { fetchData } from "../fetch";

export const getCart = (storeUUID, orderType, token) => {
  const url = `${cartAPI}/v4/carts/store/${storeUUID}/${orderType}`;
  return fetchData({
    method: "get",
    url,
    headers: {
      "x-token": token,
    },
  });
};

export const getLatestCart = (token) => {
  const url = `${cartAPI}/v4/carts/customer`;
  return fetchData({
    method: "get",
    url,
    headers: {
      "x-token": token,
    },
  });
};

export const deleteCart = (cartUUID, token) => {
  let url = `${cartAPI}/v4/carts/${cartUUID}`;
  return fetchData({
    method: "delete",
    url,
    headers: {
      "x-token": token,
    },
  });
};

export const addToCart = (data, token) => {
  const url = `${cartAPI}/v4/carts/add`;
  return fetchData(
    {
      method: "post",
      url,
      headers: {
        "x-token": token,
      },
    },
    data,
  );
};

export const updateQuantity = (cartUUID, key, quantity, token) => {
  let url = `${cartAPI}/v4/carts/update-quantity`;
  const data = {
    cart_uuid: cartUUID,
    key,
    quantity,
  };
  return fetchData(
    {
      method: "put",
      url,
      headers: {
        "x-token": token,
      },
    },
    data,
  );
};

export const updateCollectionTime = (cartUUID, collectionTime, collectionInfo, token) => {
  let url = `${cartAPI}/v4/carts/update-collection-time`;
  const data = {
    cart_uuid: cartUUID,
    collection_time: collectionTime,
    collection_info: collectionInfo,
    now_order: collectionTime ? 0 : 1,
  };
  return fetchData(
    {
      method: "put",
      url,
      headers: {
        "x-token": token,
      },
    },
    data,
  );
};

export const serviceCheckout = (data, token) => {
  const url = `${cartAPI}/v4/carts/checkout`;
  return fetchData({
    method: "POST",
    url,
    headers: {
      "x-token": token,
    },
  }, data);
};
