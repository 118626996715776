import { call, all, put, select, takeEvery } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import {
  LOAD_MENU,
  SAVE_CATEGORIES,
  SAVE_PRODUCTS,
} from "../actions/actionTypes";
import {
  actionPromiseGetChildProducts,
  actionPromiseGetToppings,
} from "../actions/menu";

import { getToppings as getToppingsService } from "../../services/menu/topping";
import { getProducts as getProductsService } from "../../services/menu";
import { getCategories as getCategoriesService } from "../../services/menu";
import { showAppLoading } from "utils";

function* loadMenu() {
  showAppLoading();
  const orderType = yield select(({ orderReducer }) => orderReducer.orderType);
  const store = yield select(({ storeReducer }) => storeReducer.store);

  const [categories, products] = yield all([
    call(getCategoriesService),
    call(getProductsService, store.uuid, orderType),
  ]);

  const productGroupByCategory = _.groupBy(products || [], "category_uuid");
  const newCategories = categories.map((item) => {
    item.products = productGroupByCategory[item.uuid] || [];
    item.product_cnt = (productGroupByCategory[item.uuid] || []).length;
    return item;
  });

  yield all([
    put({ type: SAVE_CATEGORIES, payload: newCategories }),
    put({ type: SAVE_PRODUCTS, payload: products }),
  ]);
  showAppLoading(false);
}

function* getChildProducts(action) {
  const orderType = yield select(({ orderReducer }) => orderReducer.orderType);
  const store = yield select(({ storeReducer }) => storeReducer.store);

  yield call(implementPromiseAction, action, function* () {
    return yield call(
      getProductsService,
      store.uuid,
      orderType,
      action.payload.product_uuid,
    );
  });
}

function* getTopping(action) {
  const orderType = yield select(({ orderReducer }) => orderReducer.orderType);
  const store = yield select(({ storeReducer }) => storeReducer.store);

  yield call(implementPromiseAction, action, function* () {
    return yield call(
      getToppingsService,
      store.uuid,
      orderType,
      action.payload.product_uuid,
      action.payload.option_group_uuid,
    );
  });
}

export default function* menuSaga() {
  yield takeEvery(LOAD_MENU, loadMenu);
  yield takeEvery(actionPromiseGetChildProducts, getChildProducts);
  yield takeEvery(actionPromiseGetToppings, getTopping);
}
