import i18n from 'i18next';
import enTranslation from 'languages/en/translation.json';
import jpTranslation from 'languages/jp/translation.json';
import { getCache } from 'utils/LocalStorage';

const lng = getCache('lma_language') || 'jp';

i18n.init({
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    defaultNS: 'translation',
    lng,
    resources: {
        en: {
            translation: enTranslation,
        },
        jp: {
            translation: jpTranslation,
        },
    },
    fallbackLng: 'en',
});

export default i18n;
